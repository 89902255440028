// useApiServices.ts
import { useCallback } from "react";
import useAuthToken from "../hooks/useAuthToken";
import { get_middleware_bff } from "services/middleware_services";
import { API_ENDPOINTS, ExportMessage, FilterOptions, OrdersStatsResponse, SLAState } from "../lib/types";

export function useApiServices() {
  const token = useAuthToken();

  const fetchSLAConfig = useCallback(async (): Promise<SLAState[]> => {
    if (!token) {
      throw new Error("No token available");
    }
    // Asumiendo que tu backend regresa un objeto con { code, message: [ { state, description, ... } ] }
    const resp = await get_middleware_bff<SLAState[]>("/api/config/sla", token);
    return resp.data.message; // Devuelve el array de SLAState
  }, [token]);

  const fetchFilterOptions = useCallback(async (): Promise<FilterOptions> => {
    if (!token) {
      throw new Error("No token available");
    }
    const response = await get_middleware_bff<FilterOptions>(API_ENDPOINTS.filters, token);
    return response.data.message;
  }, [token]);

  const fetchOrdersStats = useCallback(
    async (params?: URLSearchParams): Promise<OrdersStatsResponse> => {
      if (!token) {
        throw new Error("No token available");
      }
      const path = params
        ? `${API_ENDPOINTS.stats}?${params.toString()}`
        : API_ENDPOINTS.stats;

      const response = await get_middleware_bff<OrdersStatsResponse>(path, token);
      return response.data.message;
    },
    [token]
  );

  const fetchOrdersExport = useCallback(
    async (params?: URLSearchParams) => {
      if (!token) {
        throw new Error("No token available");
      }
      const path = params
        ? `${API_ENDPOINTS.export}?${params.toString()}`
        : API_ENDPOINTS.export;

      const response = await get_middleware_bff<ExportMessage>(path, token);
      return response;
    },
    [token]
  );

  return {
    fetchSLAConfig,
    fetchFilterOptions,
    fetchOrdersStats,
    fetchOrdersExport,
  };
}