import { Button, Collapse, Modal } from "components/Core"
import { Datepicker } from "flowbite-react"
import { format_date } from "helpers/datetime_helper"
import usePreRender from "hooks/usePreRender"
import { useCallback, useRef, useState } from "react"

interface IFieldDate {
  field: any
  apply_filter: any
}

const FieldDate = ({ field: _field, apply_filter }: IFieldDate) => {
  const { id, label, changeDotNotationToObject } = _field
  const [range_date, set_range_date] = useState<{ initial: Date; final: Date }>({
    initial: new Date(),
    final: new Date(),
  })
  const { _color } = usePreRender()
  const [show_calendar, set_show_calendar] = useState<boolean>()
  const modal_ref = useRef<any>(null)

  const handleClickCalendar = useCallback(() => set_show_calendar(!show_calendar), [show_calendar, set_show_calendar])

  const dotNotationToObject = (dotNotation: string, value: any) => {
    const keys = dotNotation.split(".")
    return keys.reduceRight((acc, key) => ({ [key]: acc }), value)
  }

  const apply_filter_range_date = () => {
    let _data = {
      ..._field,
      value: {
        from: `${format_date(range_date.initial)}T00:00:00.000Z`,
        to: `${format_date(range_date.final)}T23:59:59.000Z`,
      },
    }
    //  temporal fix
    if (changeDotNotationToObject) {
      Object.assign(_data, {
        report_value: dotNotationToObject(_field.field, {
          from: `${format_date(range_date.initial)}T00:00:00.000Z`,
          to: `${format_date(range_date.final)}T23:59:59.000Z`,
        }),
      })
    }
    // ----
    apply_filter(_data)
  }

  return (
    <>
      <Modal mounted={show_calendar} ref={modal_ref}>
        <div className="flex flex-col w-96">
          <div className={`w-full text-center bg_${_color} rounded-t-xl p-2`}>
            <h4 className="text-lg font-medium uppercase text-white">{label}</h4>
          </div>
          <div className="w-full flex flex-col rounded-b-xl gap-4 px-4 py-6">
            <div className="w-full flex flex-col gap-2">
              <p>Desde:</p>
              <Datepicker
                language="es"
                value={format_date(range_date.initial)}
                onSelectedDateChanged={(date: any) => set_range_date({ ...range_date, initial: date })}
                showClearButton={false}
                showTodayButton={false}
                open
              />
              <p>Hasta:</p>
              <Datepicker
                language="es"
                value={format_date(range_date.final)}
                onSelectedDateChanged={(date: any) => set_range_date({ ...range_date, final: date })}
                showClearButton={false}
                showTodayButton={false}
              />
              <div className="flex flex-row items-center justify-end">
                <button
                  onClick={() => {
                    handleClickCalendar()
                    apply_filter_range_date()
                  }}
                  type="button"
                  className={`py-2 px-4 text-center bg_${_color} text-white font-medium rounded-lg hover:opacity-90`}
                >
                  Aplicar
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Collapse name={id} header={<h1>{label}</h1>}>
        <div className="w-full flex flex-row items-center justify-center gap-2">
          <div
            className="flex flex-row items-center justify-start w-full h-12 px-2 border rounded-md border-gray-300 bg-gray-50 text-gray-900 text-sm cursor-pointer"
            onClick={handleClickCalendar}
          >
            <p>{`${format_date(range_date.initial)}, ${format_date(range_date.final)}`}</p>
          </div>
          <Button label="Aplicar" className="w-28 !p-0" onClick={apply_filter_range_date} />
        </div>
      </Collapse>
    </>
  )
}

export default FieldDate
