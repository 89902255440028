import React, { useState, useEffect, Suspense } from "react";
import type { DateRange } from "react-day-picker";
import { FilterBar } from "./components/Alertas/FilterBar";
import SummaryCards from "./components/Alertas/SummaryCards";
import ChartsSection from "./components/Alertas/ChartsSection";
import DashboardHeader from "./components/Alertas/DashboardHeader";
import LoadingState from "./components/Alertas/LoadingState";
import OrdersList from "./components/Alertas/OrdersList";
import { ResultsTable } from "./components/Alertas/LazyComponents";
import { exportDashboard } from "./components/Alertas/DashboardExporter";
import type { FilterState } from "./lib/types";
import { DEFAULT_FILTERS } from "./lib/types";
import { useQuery } from "@tanstack/react-query";
import type { FilterOptions, OrdersStatsResponse } from "./lib/api";
import { useApiServices } from "./hooks/useApiService";

const StateAlerts: React.FC = () => {
  const { fetchFilterOptions, fetchOrdersStats } = useApiServices();

  const [filterOptions, setFilterOptions] = useState<FilterOptions | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [sampleSize, setSampleSize] = useState<number>(5);
  const [filters, setFilters] = useState<FilterState>(DEFAULT_FILTERS);

  const handleFilterChange = (
    name: keyof FilterState,
    value: string[] | DateRange | undefined
  ) => {
    if (name === "orderCreationDate" && value) {
      setFilters((prev) => ({
        ...prev,
        orderCreationDate: {
          startDate: (value as DateRange).from || prev.orderCreationDate.startDate,
          endDate: (value as DateRange).to || prev.orderCreationDate.endDate,
        },
      }));
      return;
    }

    if (Array.isArray(value)) {
      setFilters((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  // Obtener las opciones de filtros desde la API real
  const { data: filterOptionsData, isLoading: isLoadingFilters } = useQuery<FilterOptions>({
    queryKey: ["/api/filters"],
    queryFn: fetchFilterOptions,
    enabled: !!fetchFilterOptions,
  });

  useEffect(() => {
    if (filterOptionsData) {
      setFilterOptions(filterOptionsData);
    }
  }, [filterOptionsData]);

  // Obtener los stats desde la API real
  const { data: statsData, isLoading: isLoadingStats } = useQuery<OrdersStatsResponse>({
    queryKey: ["/api/orders/stats", filters, sampleSize, searchTerm],
    queryFn: async () => {
      const params = new URLSearchParams();

      if (filters.delivery_type.length > 0) {
        params.append("delivery_type", filters.delivery_type.join(","));
      }

      if (filters.shipping_type.length > 0) {
        params.append("shipping_type", filters.shipping_type.join(","));
      }

      if (filters.courier.length > 0) {
        params.append("courier", filters.courier.join(","));
      }

      if (filters.classification.length > 0) {
        params.append("classification", filters.classification.join(","));
      }

      if (filters.orderCreationDate?.startDate) {
        params.append(
          "startDate",
          filters.orderCreationDate.startDate.toISOString().split("T")[0]
        );
      }

      if (filters.orderCreationDate?.endDate) {
        params.append(
          "endDate",
          filters.orderCreationDate.endDate.toISOString().split("T")[0]
        );
      }

      if (searchTerm.trim() !== "") {
        params.append("search", searchTerm);
      }

      // Si el backend soporta sampleSize, lo puedes descomentar:
      params.append("sampleSize", sampleSize.toString());

      return fetchOrdersStats(params);
    },
    refetchOnWindowFocus: false,
    enabled: !!fetchOrdersStats,
  });

  if (isLoadingFilters || isLoadingStats) {
    return <LoadingState />;
  }

  return (
    // 1) Fondo blanco y altura mínima
    <div className="min-h-screen">
      {/* Dashboard Section */}
      <div className="p-6" id="dashboard-section">
        {/* 2) Ajustamos el ancho máximo a algo más pequeño que 1200px */}
        <div className="max-w-[1200px] mx-auto space-y-8">
          <DashboardHeader onExport={exportDashboard} />

          {/* Summary Cards */}
          {statsData && <SummaryCards summaryData={statsData.summary} />}

          {/* Charts Section */}
          {statsData && (
            <ChartsSection
              stateDistribution={statsData.stateDistribution}
              distributionData={statsData.distributionData}
            />
          )}
        </div>
      </div>

      {/* Filter Bar */}
      {filterOptions && (
        <FilterBar
          filterOptions={filterOptions}
          filters={filters}
          onFilterChange={handleFilterChange}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
      )}

      {/* Results Section */}
      {statsData && (
        <div className="p-6">
          <div className="max-w-[1200px] mx-auto space-y-8">
            <Suspense fallback={<div>Loading results table...</div>}>
              <ResultsTable data={statsData.stateDistribution} />
            </Suspense>

            <OrdersList
              orders={statsData.orders}
              sampleSize={sampleSize}
              onSampleSizeChange={setSampleSize}
              currentFilters={{
                classification: filters.classification,
                deliveryType: filters.delivery_type,
                shippingType: filters.shipping_type,
                dateFrom: filters.orderCreationDate?.startDate
                  ? filters.orderCreationDate.startDate.toISOString().split("T")[0]
                  : "",
                dateTo: filters.orderCreationDate?.endDate
                  ? filters.orderCreationDate.endDate.toISOString().split("T")[0]
                  : "",
                searchTerm,
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default StateAlerts;