import { Breadcrumb, TitleScreen, FormSection, TextField, Switch } from "components/Core"
import useSourcesResources from "hooks/entities/useSourcesResources"
import { useContext, useEffect, useState } from "react"
import AuthContext from "store/context/PublicContext"
import Swal from "sweetalert2"
import usePreRender from "hooks/usePreRender"
import cn from "classnames"
import LabelSwitch from "components/Custom/LabelSwitch"
import useCombinedSourcesBFF from "hooks/bff_entities/useCombinedSourcesBFF"

const Chip: React.FC<any> = ({ enabled, label }) => {
  const [active, set_active] = useState(enabled)

  return (
    <div className="w-auto flex flex-row items-center justify-center">
      <Switch label={label} onChange={() => set_active(!active)} checked={active} className="text-sm" />
    </div>
  )
}

const ManageScreen = () => {
  const { _color } = usePreRender()

  const { user } = useContext(AuthContext)
  const [allGroupSources, setAllGroupSources] = useState<any>({
    id: "ALLSOURCES",
    name: "Todas las tiendas/bodegas",
    checked: true,
  })
  const [sourceTyped, setSourceTyped] = useState<string>("")
  const [groupSources, setGroupSources] = useState<any>([])
  const user_has_all_sources = Boolean(user?.currentSources?.some((src: string) => src === "ALL"))
  const { put_upsert_source_action } = useSourcesResources()
  const { set_loading_app, post_list_source_combined_action } = useCombinedSourcesBFF()

  useEffect(() => {
    set_loading_app(true)
    const request = async () => {
      let filters: any = {}
      if (Boolean(!user_has_all_sources)) {
        Object.assign(filters, { id: user?.currentSources })
      }
      const res: any = await post_list_source_combined_action({
        body: {
          skipRecords: 0,
          maxRecords: 1000,
        },
      })
      setGroupSources(
        (res?.data?.message?.records || []).map((source: any) => ({
          id: source.id,
          name: source.name,
          checked: source.enabled,
          schedules: source.schedule,
        })),
      )
      set_loading_app(false)
    }
    request()
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (Boolean(groupSources.length) && Boolean(groupSources?.some((src: any) => !Boolean(src.checked)))) {
      setAllGroupSources({ ...allGroupSources, checked: false })
    }
    if (Boolean(groupSources.every((src: any) => Boolean(src.checked)))) {
      setAllGroupSources({ ...allGroupSources, checked: true })
    }
    //eslint-disable-next-line
  }, [groupSources])

  const handleCheckGroupSource = (sourceId: string) => {
    setGroupSources(
      groupSources.map((source: any) => {
        if (source.id === sourceId) return { ...source, checked: !source.checked }
        return source
      }),
    )
  }

  const on_submit = async () => {
    try {
      set_loading_app(true)
      let _data: any
      if (Boolean(allGroupSources?.checked)) {
        _data = groupSources.map((src: any) => ({ id: src?.id, name: src?.name, enabled: true }))
      } else {
        _data = groupSources.map((src: any) => ({ id: src?.id, name: src?.name, enabled: src?.checked }))
      }
      let res: any = await put_upsert_source_action({ body: _data })
      if (res?.data?.code < 400) {
        Swal.fire({
          title: "Hecho",
          text: "Tiendas/bodegas actualizadas",
          icon: "success",
        })
      }
      set_loading_app(false)
    } catch (error) {
      set_loading_app(false)
    }
  }

  const filterSources = (query: string) => {
    const regex = new RegExp(query, "i") // Create regex with case-insensitive flag
    return groupSources.filter((src: any) => regex.test(src.name))
  }

  const sources_to_show: any = sourceTyped ? filterSources(sourceTyped) : groupSources

  return (
    <div className="w-full h-full flex flex-col items-start justify-start pb-10">
      <Breadcrumb
        data={[
          { name: "Configuraciones", href: "/settings" },
          { name: "Administrar tiendas/bodegas", href: "/settings/sources" },
        ]}
      />
      <div className="w-full flex flex-col justify-center items-center pt-4 px-6">
        <TitleScreen title="Administrar tiendas/bodegas" />
      </div>
      <div className="w-full flex flex-col py-2 px-4 lg:px-10">
        <div className="w-full flex flex-row items-center justify-end lg:pb-10 py-2">
          <button
            onClick={on_submit}
            className={cn(
              "w-full lg:w-48 p-2 text-sm text-md hover:opacity-80 text-white rounded-md shadow-sm hover:shadow-md transform transition duration-500 hover:scale-105",
              `bg_${_color}`,
            )}
          >
            Guardar
          </button>
        </div>
        <div className="w-full flex flex-col items-center gap-4">
          <FormSection
            title="Tiendas/bodegas"
            helperText="Tiendas/bodegas"
            number={1}
            closed={false}
            className="lg:w-4/5"
          >
            <div className="w-full flex flex-col gap-4 lg:px-10 lg:py-10">
              <TextField
                label="Tienda/bodega"
                onChange={(e: any) => setSourceTyped(e.target.value)}
                value={sourceTyped}
              />
              <div className="w-full flex flex-col items-start justify-start">
                <div className="w-full max-h-[500px] overflow-auto pr-2">
                  {Boolean(sources_to_show?.length) ? (
                    sources_to_show.map((source: any, i: number) => (
                      <div className="w-full flex flex-col gap-4 p-4 border-b border-gray-200 hover:bg-gray-50">
                        <div className="w-full flex flex-row items-center justify-between">
                          <LabelSwitch
                            key={i}
                            id={`source--${source.id}`}
                            value={source.id}
                            onChange={() => handleCheckGroupSource(source.id)}
                            checked={source.checked}
                            label={`${source.name} (${source.id})`}
                          />
                        </div>
                        <div style={{display: "none"}} className="w-full flex flex-row items-start justify-start flex-wrap gap-6">
                          {source?.schedules?.map((_sch: any, _ii: number) => {
                            return (
                              <Chip
                                key={_ii}
                                id={`source_sch--${source.id}--${_sch.deliveryType}`}
                                value={source.id}
                                onChange={() => console.log("")}
                                enabled={!_sch?.disabled}
                                label={`${_sch?.deliveryType}`}
                              />
                            )
                          })}
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>
                      {sourceTyped
                        ? `No se encontró la tienda/bodega '${sourceTyped}'`
                        : "No hay tiendas/bodegas asignadas"}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </FormSection>
        </div>
      </div>
    </div>
  )
}

export default ManageScreen
