import React from "react";
import { DateRange } from "react-day-picker";
import { FiSearch } from "react-icons/fi";
import { MultiSelectFilter } from "../MultiSelectFilter";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import { RangePopover } from "../ui/date-range-picker";
import type { FilterOptions, FilterState } from "../../lib/types";

interface FilterBarProps {
  filterOptions: FilterOptions | null;
  filters: FilterState;
  onFilterChange: (name: keyof FilterState, value: string[] | DateRange | undefined) => void;
  searchTerm: string;
  setSearchTerm: (term: string) => void;
}

export const FilterBar: React.FC<FilterBarProps> = ({
  filterOptions,
  filters,
  onFilterChange,
  searchTerm,
  setSearchTerm,
}) => {
  return (
    <div className="sticky top-0 z-50 border-y border-border p-4 shadow-sm bg-white">
      <div className="max-w-[1600px] mx-auto">
        <div className="flex flex-wrap gap-4 items-start justify-start">
          <div className="relative min-w-[250px]">
            <Label className="text-sm mb-2 block">Búsqueda</Label>
            <Input
              placeholder="Buscar por ID o estado"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="pl-10"
            />
            <FiSearch className="absolute left-3 top-[34px] text-muted-foreground h-4 w-4" />
          </div>

          <div className="min-w-[250px]">
            <Label className="text-sm mb-2 block">Rango de Fechas</Label>
            <RangePopover
              value={{
                from: filters.orderCreationDate.startDate,
                to: filters.orderCreationDate.endDate
              }}
              onChange={(range) => onFilterChange("orderCreationDate", range)}
            />
          </div>

          {filterOptions &&
            Object.entries(filterOptions).map(([key, config]) => {

              const filterId = config.id;
              const selectedValues = filters[filterId] as string[] || [];

              return (
                <MultiSelectFilter
                  id={filterId}
                  title={config.title}
                  placeholder={config.placeholder}
                  options={config.options}
                  selectedValues={selectedValues}
                  onChange={(values) => onFilterChange(filterId as keyof FilterState, values)}
                />
              );
            })
          }
        </div>
      </div>
    </div>
  );
};
