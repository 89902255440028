import React, { useState } from "react";
import { Button } from "../ui/button";
import { FiFileText } from "react-icons/fi";
import * as XLSX from "xlsx";
import { useApiServices } from "../../hooks/useApiService";

// Recibe una función que construye los parámetros (filtros) a usar en el export
interface ExportExcelButtonProps {
  buildExportParams: () => URLSearchParams;
}

export const ExportExcelButton: React.FC<ExportExcelButtonProps> = ({
  buildExportParams
}) => {
  const { fetchOrdersExport } = useApiServices();

  const [exporting, setExporting] = useState(false);
  const [progress, setProgress] = useState(0);

  const handleExportExcel = async () => {
    try {
      setExporting(true);
      setProgress(0);

      let currentFrom = 0;
      const batchSize = 1000;
      let allRecords: any[] = [];
      let keepFetching = true;
      let total = 0;

      // Construir los filtros base
      const baseParams = buildExportParams();

      while (keepFetching) {
        const params = new URLSearchParams(baseParams);
        params.set("from", String(currentFrom));
        params.set("size", String(batchSize));

        // Llamada al backend
        const result: any = await fetchOrdersExport(params);
        if (!result) break;

        const { totalItems, orders } = result;
        total = totalItems || 0;

        // Mapeamos para stringificar campos no simples
        const sanitized = orders.map((row: Record<string, any>) => {
          const newRow: Record<string, any> = {};
          for (const key in row) {
            const val = row[key];
            if (val !== null && typeof val === "object") {
              newRow[key] = JSON.stringify(val);
            } else {
              newRow[key] = val;
            }
          }
          return newRow;
        });

        allRecords.push(...sanitized);

        currentFrom += orders.length;

        // Actualizamos la barra de progreso
        const percent = total > 0 ? Math.round((currentFrom / total) * 100) : 0;
        setProgress(percent);

        if (orders.length < batchSize || currentFrom >= total) {
          keepFetching = false;
        }
      }

      if (allRecords.length === 0) {
        alert("No hay registros para exportar.");
        return;
      }

      // Creamos la hoja (worksheet)
      const ws = XLSX.utils.json_to_sheet(allRecords);

      // Creamos el libro (workbook)
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "HojaOrdenes");

      // Usamos XLSX.writeFile para generar y descargar el Excel
      const fileName = `ordenes_export_${new Date().toISOString().slice(0,10)}.xlsx`;
      XLSX.writeFile(wb, fileName);

      console.log("Excel debería haberse descargado:", fileName);

    } catch (error) {
      console.error("Error al exportar:", error);
    } finally {
      setExporting(false);
      setProgress(100);
    }
  };

  return (
    <div>
      <Button
        onClick={handleExportExcel}
        variant="outline"
        className="inline-flex items-center justify-center gap-2 bg-white text-black hover:bg-gray-100"
        disabled={exporting}
      >
        <FiFileText className="h-4 w-4" />
        {exporting ? "Exportando..." : "Exportar Excel"}
      </Button>

      {exporting && (
        <div className="mt-2 w-[200px] h-3 bg-gray-200 relative">
          <div
            className="h-3 bg-blue-500"
            style={{ width: `${progress}%` }}
          />
        </div>
      )}
    </div>
  );
};